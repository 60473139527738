import { render, staticRenderFns } from "./ExplodingParticles.vue?vue&type=template&id=25df8570&scoped=true&"
import script from "./ExplodingParticles.vue?vue&type=script&lang=js&"
export * from "./ExplodingParticles.vue?vue&type=script&lang=js&"
import style0 from "./ExplodingParticles.vue?vue&type=style&index=0&id=25df8570&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25df8570",
  null
  
)

export default component.exports